
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import Underconstruction from 'components/Underconstruction';

function App() {
    return (
        <Underconstruction />
    )
}

export default App