import React, { useEffect, useRef, useState } from 'react'
import logoBanner from 'assets/img/logo.gif';
import videoplayer from 'assets/img/video.mp4';
import styled from 'styled-components';
import { Container, Row } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import { RiMapPin2Line } from "react-icons/ri";
import { PiEnvelopeSimpleThin } from "react-icons/pi";
import { FiPhoneCall } from "react-icons/fi";
import { CiGlobe } from "react-icons/ci";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import videojs from 'video.js';
import VideoJS from './VideoJS';
import UseWindowHeight from './UseWindowHeight';

import imgComming from 'assets/img/bg-underconstruction.jpg';

function Underconstruction() {
    const videoEl = useRef(null);
    const playerRef = useRef(null);
    const videoJsOptions = {
        autoplay: true,
        controls: false,
        responsive: false,
        fluid: true,
        loop: true,
        sources: [{
            src: videoplayer,
            type: 'video/mp4'
        }]
    };
    const handlePlayerReady = (player) => {
        playerRef.current = player;
    
        // You can handle player events here, for example:
        player.on('waiting', () => {
          videojs.log('player is waiting');
        });
    
        player.on('dispose', () => {
          videojs.log('player will dispose');
        });
    };
    // const attemptPlay = () => {
    //     videoEl && videoEl.current &&
    //     videoEl.current.play().catch(error => {
    //         console.error("Error attempting to play", error);
    //     });
    // };
    // useEffect(() => {
    //     attemptPlay();
    // }, []);

    const [isOpen, setIsOpen] = useState(false);
    const toggleDiv = () => {
        setIsOpen(!isOpen);
    };

    const height = UseWindowHeight();
    return (
    
    <Section height={height}>
        <Container fluid className='position-relative hei1'>
            <Container className="notes">
                <div className='headline'>
                    <div className='img-headline'>
                        <img 
                            src={logoBanner}
                            className="max-img opacit-0 image-logo-banner"
                            title="R ADVISORY" 
                            alt="R ADVISORY" 
                            width={200}
                        />
                    </div>
                    <div className='copy-headline'>
                        <p>
                            Our new website is coming soon to provide you with information about our <br />excellent services in public affairs and strategic communications. Stay tuned!
                        </p>
                        <div className='d-flex address-office'>
                            <button onClick={toggleDiv} className='linkContact'>Contact here</button>
                        
                            <CSSTransition 
                                in={isOpen}
                                timeout={100}
                                classNames="slide"
                                unmountOnExit
                            >
                                <div className="sliding-div ps-lg-3 mt-3 mt-lg-0">
                                    <div> <RiMapPin2Line /> Jl. Duren Tiga Selatan No.7 Kalibata, Pancoran - Jakarta Selatan DKI Jakarta 12740</div>
                                    <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                                        <div className='pe-2'> <FiPhoneCall /> 021-38815011</div>
                                        <div className='pe-2'> <PiEnvelopeSimpleThin /> networking@rbadvisory.id</div>
                                        <div> <CiGlobe /> www.rbadvisory.id</div>
                                    </div>
                                </div>
                            </CSSTransition>
                        </div>
                    </div>
                </div>
            </Container>
            
        </Container>
        <Container fluid className='hei2'>
            <Row>
                <div className='video-player p-5'>
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                    {/* <video
                        src={videoplayer}
                        ref={videoEl}
                        playsInline
                        loop
                        muted
                        controls
                        alt="All the devices"
                    /> */}
                </div>
            </Row>
        </Container>
    </Section>
  )
}

const Section = styled.section `
    --calculated-height: ${(props) => `calc(100vh - (${props.height}px - 250px))`};
    --height-trnsform: calc(var(--calculated-height) / 0.96);
    --height-mobile: calc(var(--calculated-height) / 2.8);
    overflow: hidden;
    height:100vh;
    background:url(${imgComming}) no-repeat;
    background-size: cover;
    .hei1 {
        /* height: calc(100vh - 63%); */
        height: var(--calculated-height);
    }
    .hei2 {
        /* height: calc(100vh - 38%); */
        height: calc(100vh - (50px + var(--calculated-height)));
        overflow: hidden;
    }
    .headline {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding:1rem 0;
        .copy-headline {
            line-height: 1.5;
            font-size: clamp(.8rem, 4vw, 21px);
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
        }
        .img-headline {
            width: 20%;
        }
        @media screen and (min-width: 1080px) {
            height: 290px;
            padding:3rem 0;
            .copy-headline {
                width: 80%;
            }
        }
        
        @media screen and (max-width: 1080px) {
            .copy-headline {
                p {
                    font-size: 14px;
                    text-align:center
                }
            }
        }
    }
    .linkContact {
        padding: 0.2rem .5rem;
        font-size: .9rem;
        text-transform: uppercase;
        border: solid 1px transparent;
        background-color: #293990;
        width: 140px;
        border-radius: 30px;
        text-decoration: none;
        color:#fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg {
            font-size: 28px;
        }
    }
    .video-player {
        .vjs-fluid {
            padding-top: 170%;
            border-radius: 10px;
            overflow: hidden;
        }
        video {
            width: auto;
            height: 70vh;
            transform: translate(-40%, calc(-1 * var(--height-mobile)));
        }
        @media screen and (min-width: 1080px) {
            .vjs-fluid {
                padding-top:32.8%;
            }
            video {
                width: 100%;
                height: auto;
                transform: translate(0%, calc(-1 * var(--height-trnsform)));
            }
        }
    }
    .address-office {
        align-items: center;
        flex-direction: column;
        @media screen and (min-width: 1080px) {
            flex-direction: row;
            min-height: 90px;
        }
    }
    .sliding-div {
        /* padding: 0px 20px;
        margin-top: 10px; */
        /* position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgb(41 57 144 / 64%);
        color: #fff; */
        div {
            font-size: 9px;
            /* margin:.5rem 0; */
        }
        svg {
            font-size: 10px;
        }
        @media screen and (min-width: 1080px) {
            align-items: center;
            div {
                font-size: clamp(12px, 4vw, 14px);
            }
            svg {
                font-size: 20px;
            }
        }
        @media screen and (max-width: 1080px) {
            text-align: center;
        }
    }
    .slide-enter {
        max-height: 0;
        overflow: hidden;
        transition: max-height .3s ease;
    }
    .slide-enter-active {
        max-height: 100px;
    }

    .slide-exit {
        max-height: 100px;
        overflow: hidden;
        transition: max-height .3s ease;
    }

    .slide-exit-active {
        max-height: 0;
    }

`
export default Underconstruction